<template>
  <Popup ref="popupRef" title="驳回该任务" width="566px" confirm-text="提交投诉" @confirm="confirm" @close="resetData">
    <div class="label required">驳回理由</div>
    <el-input type="textarea" :rows="5" maxlength="100" 
    show-word-limit placeholder="请输入驳回该任务的理由" v-model="form.rejectReason"></el-input>
    <div class="label mt24">添加图片</div>
    <div class="img-list">
      <!-- <div class="img-item" v-for="(item, index) in 4" :key="index">
        <img class="btn-close" src="@/assets/images/close.png" @click="removeImage">
        <img class="img" src="@/assets/images/logo.png">
      </div> -->
      <div class="img-item" v-for="(item,index) in form.rejectPic" :key="index">
        <img class="btn-close" src="@/assets/images/close.png" @click="removeImage(index)">
        <LoadImage class="img" :src="item.url" ></LoadImage>
      </div>
      <label for="inputId">
        <div class="upload-img">
          <img src="@/assets/images/add-plus.png">
          <div>添加图片</div>
        </div>
        <input id="inputId" type="file" multiple accept=".jpg,.png" style="display: none;"  @change="chooseImage">
      </label>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import LoadImage from '@/components/LoadImage'
import UploadImage from '@/components/UploadImage'
import { taskManageApi } from '@/api/task.js'
import uploadImageUtil from '@/utils/uploadImageUtil.js'
export default {
  components: {
    Popup,
    LoadImage,
    UploadImage
  },
  data() {
    return {
      taskId: '',
      serialNo: '',
      form: {
        rejectReason: '',
        rejectPic: []
      }
    }
  },
  methods: {
    open(info) {
      this.taskId = info.taskId
      this.serialNo = info.serialNo
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetData() {
      this.form.rejectReason = ''
    },
    confirm() {
      if(!this.form.rejectReason.trim()) return this.$tips({message: '驳回理由不能为空', type: 'warning'})
      const rejectPic = this.form.rejectPic.map(item => item.ossUrl)
      const formData = {
        'type': 1,
        'taskId': this.taskId,
        'extParam': {
          'auditAction': 2,
          'serialNo': this.serialNo,
          'rejectPic': rejectPic.join(','),
          'rejectReason': this.form.rejectReason,
				}
      }
      console.log('formData',formData)
      taskManageApi(formData).then(res => {
        console.log('驳回任务', res)
        this.$tips({message: res.msg, type: 'success'})
        this.close()
        this.$emit('update')
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    async chooseImage(e) {
      console.log('上传文件',e.target.files)
      const fileList = e.target.files
      if(fileList.length == 0) return
      if(fileList.length > 9 || fileList.length + this.form.rejectPic.length > 9) {
        return this.$tips({message: '最多上传9张图片', type: 'error'})
      }
      const { uploadImageAsync } = await uploadImageUtil()
      for (const file of fileList) {
        uploadImageAsync(file).then(res => {
          console.log('上传成功', res)
          this.form.rejectPic.push(res)
        }).catch(err => {
          this.$tips({message: '上传图片失败', type: 'error'})
        })
      }  
    },
    removeImage(index) {
      this.form.rejectPic.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #65666F;
  margin-bottom: 8px;
}
.required {
  position: relative;
  &::before {
    content: '*';
    position: absolute;
    top: 2px;
    left: -6px;
    color: var(--theme-color-red);
  }
}
.img-list {
  display: flex;
  flex-wrap: wrap;
  .img-item {
    position: relative;
    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
  .img {
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
  }
}

.upload-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 104px;
  border-radius: 4px;
  border: 1px dashed #D9D9D9;
  background-color: #FAFAFA;
  font-size: 14px;
  color: #505E5C;
  cursor: pointer;
  &:hover {
    border: 1px dashed var(--theme-color);
  }
  img {
    width: 18px;
    height: 18px;
  }
}
</style>